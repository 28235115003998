import React, { useEffect } from "react"
import { SubTitleSection, Text, BlogContainer } from "../atomics"
import Layout from "../layout"
import SEO from "../seo"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { GiStopwatch } from "react-icons/gi"

import { Link } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { format, parseISO } from "date-fns"
import { URL_INSTA_FLYNOW, URL_YOUTUBE } from "../../constants"
import { calcReadingTime } from "../../utils/string"
import { FaInstagram, FaYoutube } from "react-icons/fa"
import { MdMail } from "react-icons/md"

function Post({ pageContext: post }) {
  useEffect(() => {
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "View",
      // string - required - Type of interaction (e.g. 'play')
      action: "View",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: "View Post",
      // number - optional - Numeric value associated with the event. (e.g. A product ID)
      value: post.title,
    })
  }, [post])
  return (
    <Layout>
      <SEO
        pageId="post"
        title={post.title}
        description={post.description + " | " + post.keywords}
        imageUrl={
          post.banner
            ? "https://appflynow.com" + post.banner.publicURL
            : undefined
        }
        meta={[
          {
            name: "type",
            content: "article",
          },
          {
            name: "date",
            content: post.date,
          },
          {
            name: "author",
            content: post.author,
          },
          {
            name: "url",
            content: "https://appflynow.com/blog/" + post.url,
          },
          {
            property: "og:type",
            content: "article",
          },
          {
            property: "og:date",
            content: post.date,
          },
          {
            property: "og:author",
            content: post.author,
          },
          {
            property: "og:url",
            content: "https://appflynow.com/blog/" + post.url,
          },
        ]}
      />
      <BlogContainer>
        <br />
        <SubTitleSection className="title-post">{post.title}</SubTitleSection>
        <Text style={{ marginTop: "1rem" }} className="text-description shadow">
          {post.description}
        </Text>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontFamily: "Roboto",
            marginTop: 10,
          }}
        >
          <h6>Por {post.author}</h6>
          <h6>{format(parseISO(post.date), "dd/MM/yyyy")}</h6>
        </div>
        <div
          style={{
            display: "flex",
            fontFamily: "Roboto",
            marginTop: 6,
            alignItems: "center",
          }}
        >
          <GiStopwatch size={20} />
          <h6 style={{ margin: 2, marginTop: 2 }}>
            {calcReadingTime(post.content)} min
          </h6>
        </div>
        <br />

        <ReactMarkdown
          children={post.content}
          className="post-content"
          rehypePlugins={[rehypeRaw]}
        />

        <br />
        <Text className="text-description" style={{ fontSize: "1rem" }}>
          <strong style={{ marginBottom: 20 }}>
            {" "}
            Gostou do nosso conteúdo? <br /> 
          </strong>
          <MdMail /> Cadastre-se gratuitamente em nossa 
          <Link to="/newsletter" target="_blank" rel="noopener noreferrer">
            {" "}
            <b>
            NEWSLETTER.
            </b>
          </Link>
          <br />
          <FaYoutube /> Inscreva-se em nosso {" "}
          <a href={URL_YOUTUBE} target="_blank" rel="noopener noreferrer">
          <b>
            CANAL do YOUTUBE.
          </b>
          </a>
            <br/>
          <FaInstagram /> Siga nossa página no {" "}
          <a href={URL_INSTA_FLYNOW} target="_blank" rel="noopener noreferrer">
            <b>
            INSTAGRAM.
            </b>
          </a>
        </Text>
        <br />
      </BlogContainer>
    </Layout>
  )
}

export default Post
